
import { computed, defineComponent, onMounted, ref, defineProps } from "vue";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/BackStoreEnum";

interface Declaration {
  id: string,
  numeroLicence: string
}

export default defineComponent({
  name: "reseau-documents",
  components: {

  },
  props: {
    reseau: Object
  },

  setup(props) {

    const store = useStore();
    let documents = ref([]);
    const DOCUMENT_ROOT_PATH = ref(process.env.VUE_APP_DOCUMENT_ROOT);

    onMounted(() => {
      getDocuments();
    });

    const getDocuments = () => {
      store.dispatch(Actions.GET_DOCUMENTS_BY_RESEAU, {reseauId: props.reseau?.id, urlParameters: {size: 20}})
        .then((res) => {
          documents.value = res.content;          
        });
    }

    return {
      documents,
      DOCUMENT_ROOT_PATH
    }
  }
})

