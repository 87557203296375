
import { defineComponent, computed } from "vue";
import ObservationRecap from "@/components/customs/ObservationRecap.vue"
import VsatListeRecap from "@/components/networks/vsat/form/liste/VsatListeRecap.vue"

export default defineComponent({
  name: "vsat-recap",
  props: {
     data : {type:Object, default:null}
  },
  setup(props){

    const hasObservation =computed(() => {
      return props.data.observation != "" && props.data.observation != undefined && props.data.observation != null
    })

    return { hasObservation }
  },
  components: {ObservationRecap,VsatListeRecap},
});
