
import { onMounted, computed, ref, getCurrentInstance, defineComponent} from "vue";
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name: 'reseau-info',
  components: {

  },
  props: {
    declaration: {Object, required: true},
    reseau: {Object, required: true}
  },
  setup(props) {

    const router = useRouter();
    const key = ref<number>(0);
    const emitter = getCurrentInstance()?.appContext.config.globalProperties.emitter;

    onMounted(() => {
    })
  }
});

