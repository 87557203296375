
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import ReseauInfo from '@/views/pages/reseau/details/section/ReseauInfo.vue';
import ReseauRecap from '@/views/pages/reseau/details/section/ReseauRecap.vue';
import ReseauDocuments from '@/views/pages/reseau/details/section/ReseauDocuments.vue';
import store from "@/store";
import { Actions, Mutations } from "@/store/enums/BackStoreEnum";


export default defineComponent({
  name: "reseau-details",
  components: {
    ReseauInfo,
    ReseauRecap,
    ReseauDocuments
  },
  props: {
  },
  setup() {

    const route = useRoute();
    const declaration = ref<any>(null);
    const loadingDeclaration = ref<boolean>(true);
    const loadingReseau = ref<boolean>(true);
    const router = useRouter();
    const declarationId = ref<string|null>(null);
    const reseauId = ref<string|null>(null);
    const reseau = ref();

    const getDeclaration = (id) => {
      loadingDeclaration.value = true;

      ApiService.get(`/api/declaration/${id}`).then((res: any) => {
          loadingDeclaration.value = false;
          declaration.value = res.data;
          
      }).catch(error => { 
          loadingDeclaration.value = false;
          router.push({name: '404'})
      });
    }

    const getReseauById = (reseauId) => {
      loadingReseau.value = true;

      store.dispatch(Actions.GET_RESEAU_BY_ID, reseauId)
        .then((data) => {
          loadingReseau.value = false;
          reseau.value = data;          
          
        }, error => {
          //redirection si le réseau là n'existe pas
          router.push({name: "404"});
        });
    }

    onMounted(() => {
        setCurrentPageBreadcrumbs("Détails", ["Réseaux"]);

        //Déclaration id
        declarationId.value = route.params.declarationId as string;
        reseauId.value = route.params.reseauId as string;

        getDeclaration(declarationId.value);
        getReseauById(reseauId.value);
        
    });
    

    
    return {
      declaration,
      declarationId,
      loadingDeclaration,
      loadingReseau,
      reseau
    };
  },
});
