
import type { ElTable } from 'element-plus'
import type { TableColumnCtx } from 'element-plus/es/components/table/src/table-column/defaults'

import { defineComponent, computed,onMounted,ref} from "vue";
import {useEntGeos} from "@/composables/useEntGeos";
import {useBande} from "@/composables/useBande";
import _ from 'lodash';
interface Entry {
  key:number;
  code: string;
  nom: string;
  bande_id:string;
  site_longitude: string;
  site_latitude: string;
  site_entGeo_id : string;
  site_nomVilleInternationale:string|undefined,
  debit: number|undefined;
  frequenceRx: number|undefined;
  frequenceTx: number|undefined;
  nomClient:string|undefined
}


interface SpanMethodProps {
  row: Entry
  column: TableColumnCtx<Entry>
  rowIndex: number
  columnIndex: number
}
export default defineComponent({
  name: "vsat-liste-recap",
  props: {
     data : {type:Array,default:[]},
  },
  components: {},
  setup(props, context) {
    

    const {getVilleDescription,getVilleInterationaleDescription} = useEntGeos();
    var flatten = require('flat')

    const stations = computed(() => {
      var data = [] as Entry[]

        props.data.forEach((item,index) => {
          
          var station = Object.assign({id:"",stationA:station,stationB:station}, item)
          var stationA = {} as Entry
          stationA.key = index+1
          stationA = {...stationA, ...station.stationA}
          stationA = flatten(stationA,{delimiter:"_"})
          stationA.site_entGeo_id = getVilleDescription(stationA.site_entGeo_id,true)
          data.push(stationA)
          var stationB = {} as Entry
          stationB.key = index+1
          stationB = {...stationB, ...station.stationB}
          stationB = flatten(stationB,{delimiter:"_"})
          stationB.site_entGeo_id = (stationB.site_nomVilleInternationale !="" && stationB.site_nomVilleInternationale != undefined) 
                                    ? getVilleInterationaleDescription(stationB.site_entGeo_id, stationB.site_nomVilleInternationale) 
                                    : getVilleDescription(stationB.site_entGeo_id,true)
          data.push(stationB)
        })
      return data
   })


    const objectSpanMethod = ({
        row,
        column,
        rowIndex,
        columnIndex,
      }: SpanMethodProps) => {
        if (columnIndex === 0) 
        {
          if (rowIndex % 2 === 0) {
            return {
              rowspan: 2,
              colspan: 1,
            }
          } else {
            return {
              rowspan: 0,
              colspan: 0,
            }
          }
      }
    }

    const indexePosition = (index) => {
      var position : number ;
       if(index == 0) position =1
       else if(index == 1) position = 1
       else if(index == 2) position = 2
       else 
       {
        position = index % 2 == 0  ? (index/2)+1 : index-1
       }
       return position;
    }

    return {
      getVilleDescription,
      objectSpanMethod,
      stations,
      indexePosition
    };
  }
});
