
import { onMounted, computed, ref, getCurrentInstance, defineComponent} from "vue";
import { useRoute, useRouter } from 'vue-router';
import BlrRecap from '@/views/pages/reseau/details/recap/blr/BlrRecap.vue';
import FhRecap from '@/views/pages/reseau/details/recap/fh/FhRecap.vue';
import GsmRecap from '@/views/pages/reseau/details/recap/gsm/GsmRecap.vue';
import PmrRecap from '@/views/pages/reseau/details/recap/pmr/PmrRecap.vue';
import RadarRecap from '@/views/pages/reseau/details/recap/radar/RadarRecap.vue';
import VsatRecap from '@/views/pages/reseau/details/recap/vsat/VsatRecap.vue';

export default defineComponent({
  name: 'reseau-recap',
  components: {
    BlrRecap,
    FhRecap,
    GsmRecap,
    PmrRecap,
    RadarRecap,
    VsatRecap
  },
  props: {
    declaration: {Object, required: true},
    reseau: {Object, required: true}
  },
  setup(props) {

    const router = useRouter();
    const key = ref<number>(0);
    const emitter = getCurrentInstance()?.appContext.config.globalProperties.emitter;

    onMounted(() => {
    })
  }
});

